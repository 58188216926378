export const getRecurringKeyToValue = (key) =>{
    switch (key) {
        case "chkOneTimeOnly":
            return "One Time Only"
        case "chkWeek":
            return "Week"    
        case "chk2Weeks":
            return "2 Weeks"  
        case "chkMonth":
            return "Month"  
        case "chk2Months":
             return "2 Months"
        case "chkQuarter":
            return "Quarter"    
        case "chk6Months":
            return "6 Months"     
        case "chkYear":
            return "Year"           
        default:
            return key;
    }
};

export const getRecurringValueToKey = (val) =>{
    switch (val) {
        case "Week":
            return "chkWeek"
    
        default:
            return val;
    }
};

export const getPaymentAmount = (amount, installment, convFee, IsConvFeeSelected, recurringMethod) => {

    if(amount <= 0 && !IsConvFeeSelected){
        return amount;
       }else if(amount <= 0 && IsConvFeeSelected){
        return parseFloat(convFee)
       }else if(amount > 0 && installment && !IsConvFeeSelected && recurringMethod && recurringMethod !== 'Subscription'){
        return getDecimalPoint(amount, installment,'');
       }else if(amount > 0 && !installment && IsConvFeeSelected){
        return getDecimalPoint(amount, '', convFee);
       }else if(amount > 0 && installment && IsConvFeeSelected && recurringMethod && recurringMethod !== 'Subscription'){
        return getDecimalPoint(amount, installment, convFee);
       }else if(amount > 0 && installment && IsConvFeeSelected && recurringMethod && recurringMethod === 'Subscription'){
        return getDecimalPoint(amount, '', convFee);
       }else if(amount > 0 && installment && !IsConvFeeSelected && recurringMethod && recurringMethod === 'Subscription'){
        return parseFloat(amount);
       }else{
        return parseFloat(amount);
       }
};


export const countNoOfDecimals = (num)=> {
    // Check if it's a number and has a decimal point
    if (Math.floor(num) !== num && !isNaN(num)) {
      return num.toString().split(".")[1].length;
    }
    return 0;
  };

export const getDecimalPoint = (amount, installments, convFee) => {
    let amt = countNoOfDecimals(parseFloat(amount)) > 2 ? parseFloat(removedecimal(amount)) : parseFloat(amount);
    let addfee = countNoOfDecimals(parseFloat(convFee)) > 2 ? parseFloat(removedecimal(convFee)) : parseFloat(convFee);
  
    if(installments) amt = Math.round((amt / installments + Number.EPSILON) * 100) / 100;
  
    if(convFee) amt = Math.round((amt + addfee + Number.EPSILON) * 100) / 100;
     amt = parseFloat(removedecimal(amt));
    return amt;
};

export const getRemovedFee = (amount, fee) => {
    let amt = countNoOfDecimals(parseFloat(amount)) > 2 ? parseFloat(removedecimal(amount)) : parseFloat(amount);
    let addfee = countNoOfDecimals(parseFloat(fee)) > 2 ? parseFloat(removedecimal(fee)) : parseFloat(fee);
 
    if(fee) amt = Math.round((amt - addfee + Number.EPSILON) * 100) / 100;
    amt = parseFloat(removedecimal(amt));
    return amt;
};
export const getTaxDeductableAmount = (amount, tax) => {

    let amt = countNoOfDecimals(parseFloat(amount)) > 2 ? parseFloat(removedecimal(amount)) : parseFloat(amount);
    if(tax > 0) amt = (amt * tax)/100;
    if(tax === 0) amt = 0;
    amt = parseFloat(removedecimal(amt));
    return amt;
};


export const removedecimal=(itemvalue)=> {
    if (itemvalue.toString().indexOf('.') !== -1) {
        var substr = itemvalue.toString().split(".");
        if (substr[1].length > 2)
            itemvalue = substr[0] + "." + substr[1].substr(0, 2);
    }
 
    return itemvalue;
};

export const replaceAll = (str,mapObj) => {
    var re = new RegExp(Object.keys(mapObj).join("|"),"gi");

     return str.replace(re, (matched)=>{
        return mapObj[matched];
    });
   
};

export const getButtonStyles = (settings) => {
    if(settings){
        const x = settings.split(';');

        let obj = {};
        for (let i = 0; i < x.length; i++) {
            let split = x[i].split(':');
            if(split[0].includes(' '))  {
                obj[split[0].trim()] = split[1].trim();
            }else{
                obj[split[0]] = split[1];
            }
            
        }
        return obj;
    }else{
        return null;
    }
    
};

export const getButtonRowVal = (row) => {
    let rowval = 0;
    switch (row) {
        case "one":
          rowval = 12;
          break;
        case "two":
          rowval = 2;
          break;
        case "three":
          rowval = 3;
          break;
        case "four":
          rowval = 4;
          break;
        default:
          rowval = 4;
      }
      return rowval;
}



export const CalcConvenienceFee =(D, rate, P, installment, periodicity, EN, recMethod) => {
    
    if (isNaN(Number(D))) return D = 0;
    let F =0;
    let R = parseFloat(rate/100);
    if (R >= 1) {
        throw new Error("Discount rate must be less than 1.");
      }
      if(EN) F = (D * R + P) / (1 - R);
      else  F = (D * R + P) ;
    
      F = F + 0.005;

    return parseFloat(parseInt(F * 100)/100);  
  
}
function Rec_Calculation(txtvalue, Installmentvalue) {

    if (txtvalue === 0)

        return Installmentvalue;

    else if (txtvalue <= Installmentvalue)

        return txtvalue;

    else if (txtvalue < 0)

        return 'false';

    else

        return 'false';

}
 
function GetTodayDate() {

    var tdate = new Date();

    var dd = tdate.getDate(); //yields day

    var MM = tdate.getMonth(); //yields month

    var yyyy = tdate.getFullYear(); //yields year

    var xxx = (MM + 1) + "/" + dd + "/" + yyyy;
 
    return xxx;

}

const getFormattedDate = (date)=> {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}
 
export const calculateEndPeriod = (recurringtype,endDate,period) =>{

    let transaction = GetTodayDate();
    let en = getFormattedDate(new Date(endDate));
    let txtvalue = '';
    let limit = '';

    if (recurringtype === 'installment') {
        
        limit = '998';
    }
    else if (recurringtype === 'subscription') {
       
        limit = '999';
    }

    if (txtvalue === '')
        txtvalue = 0;

    let Installmentvalue = '';

   

    if (en !== '') {

        let t1 = transaction.split('/');
        let en1 = en.split('/');

        let tdate1 = new Date(t1[2], (t1[0] - 1), t1[1]);
        let endate1 = new Date(en1[2], (en1[0] - 1), en1[1]);

        let Diff = Math.ceil((endate1 - tdate1) / (1000 * 60 * 60 * 24));

        if (period.toLowerCase() === 'week') {

            if (Diff < 7) {
             
                return {data:'',error:'Please select valid End Date at settings'};
            } else {
                if (txtvalue > limit) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }
               
                if (parseInt(Diff / 7) < 1) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                Installmentvalue = parseInt(Diff / 7) + 1;
                let returnvalue = Rec_Calculation(txtvalue, Installmentvalue);
                if (returnvalue === 'false') {
                   return {data:'',error:'# of Installments should not be greater than ' + Installmentvalue};
                }else{
                    return {data: returnvalue,error:''};
                }
                
            }

            
        }

        if (period.toLowerCase() === '2 weeks') {

            if (Diff < 14) {
                
                return {data:'',error:'Please set valid End Date at settings'};
            } else {
                if (txtvalue > limit) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }
               
                if (parseInt(Diff / 14) < 1) {
                   return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                Installmentvalue = parseInt(Diff / 14) + 1;
                let returnvalue = Rec_Calculation(txtvalue, Installmentvalue);
                if (returnvalue === 'false') {
                   return {data:'',error:'# of Installments should not be greater than ' + Installmentvalue};
                }else{
                    return {data: returnvalue,error:''};
                }


            }
        }
        if (period.toLowerCase() === 'month') {
            if (Diff < 30) {
                  return {data:'',error:'Please set valid End Date at settings'};
            } else {
                if (txtvalue > limit) {
                   return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }
               
                if (parseInt(Diff / 30) < 1) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                Installmentvalue = parseInt(Diff / 30) + 1;
                let returnvalue = Rec_Calculation(txtvalue, Installmentvalue);
                if (returnvalue === 'false') {
                     return {data:'',error:'# of Installments should not be greater than ' + Installmentvalue};
                  
                }else{
                    return {data: returnvalue,error:''};
                }
                
            }
        }
        if (period.toLowerCase() === '2 months') {
            if (Diff < 61) {
                 //ShowfbAlert('Please set valid End Date at settings');
                 return {data:'',error:'Please set valid End Date at settings'};
            } else {
                if (txtvalue > limit) {
                    //ShowfbAlert('# of Installments should be greater than 1 and less than or equal to ' + limit);
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }
                
                if (parseInt(Diff / 61) < 1) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                Installmentvalue = parseInt(Diff / 61) + 1;
                let returnvalue = Rec_Calculation(txtvalue, Installmentvalue);
                if (returnvalue === 'false') {
                    return {data:'',error:'# of Installments should not be greater than ' + Installmentvalue};
                }else{
                    return {data: returnvalue,error:''};
                }

                
            }
        }
        if (period.toLowerCase() === 'quarter') {
            if (Diff < 91) {
                return {data:'',error:'Please set valid End Date at settings'};
              
            } else {
                if (txtvalue > limit) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }
                //if (parseInt(Diff / 91) > 999 || parseInt(Diff / 91) < 1) {
                if (parseInt(Diff / 91) < 1) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                Installmentvalue = parseInt(Diff / 91) + 1;
                let returnvalue = Rec_Calculation(txtvalue, Installmentvalue);
                if (returnvalue === 'false') {
                    return {data:'',error:'# of Installments should not be greater than ' + Installmentvalue};
                }else{
                    return {data: returnvalue,error:''};
                }

                
            }
        }
        if (period.toLowerCase() === '6 months') {
            if (Diff < 183) {
                return {data:'',error:'Please set valid End Date at settings'};
            } else {
                if (txtvalue > limit) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }
                //if (parseInt(Diff / 183) > 999 || parseInt(Diff / 183) < 1) {
                if (parseInt(Diff / 183) < 1) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                Installmentvalue = parseInt(Diff / 183) + 1;
                let returnvalue = Rec_Calculation(txtvalue, Installmentvalue);
                if (returnvalue === 'false') {
                    return {data:'',error:'# of Installments should not be greater than ' + Installmentvalue};
                   
                }else{
                    return {data: returnvalue,error:''};
                }

            }
        }
        if (period.toLowerCase() === 'year') {
            if (Diff < 365) {
               return {data:'',error:'Please set valid End Date at settings'};
            
            }
            else {
                if (txtvalue > limit) {
                   return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                //if (parseInt(Diff / 365) > 999 || parseInt(Diff / 365) < 1) {
                if (parseInt(Diff / 365) < 1) {
                    return {data:'',error:'# of Installments should be greater than 1 and less than or equal to ' + limit};
                }

                Installmentvalue = parseInt(Diff / 365) + 1;
                let returnvalue = Rec_Calculation(txtvalue, Installmentvalue);
                if (returnvalue === 'false') {
                   return {data:'',error:'# of Installments should not be greater than ' + Installmentvalue};
                }else{
                    return {data: returnvalue,error:''};
                }

            }
        }

      
    }

   
};

export const filterPeriodicityByEndDate = (endDate,period) =>{
    let transaction = GetTodayDate();
    let en = getFormattedDate(new Date(endDate));
  
    if(en !== ''){
        let t1 = transaction.split('/');
        let en1 = en.split('/');

        let tdate1 = new Date(t1[2], (t1[0] - 1), t1[1]);
        let endate1 = new Date(en1[2], (en1[0] - 1), en1[1]);

        let Diff = Math.ceil((endate1 - tdate1) / (1000 * 60 * 60 * 24));
        if (period.toLowerCase() === 'week') {

            if (Diff < 7) return false;
            else return true;
        }
        if (period.toLowerCase() === '2 weeks') {

            if (Diff < 14) return false;
            else return true;
        }
        if (period.toLowerCase() === 'month') {
            if (Diff < 30) return false;
            else return true;
        }
        if (period.toLowerCase() === '2 months') {
            if (Diff < 61) return false;
            else return true;
        }
        if (period.toLowerCase() === 'quarter') {
            if (Diff < 91) return false;
            else return true;
        }
        if (period.toLowerCase() === '6 months') {
            if (Diff < 183) return false;
            else return true;
        }
        if (period.toLowerCase() === 'year') {
            if (Diff < 365) return false;
            else return true;
        }
    }
}

export const checkDateExpired = (startdate,enddate) => {
  
    let expired = false;
    const tDate = new Date();
    const tmonth = (tDate.getMonth() + 1).toString().padStart(2, '0');
    const tday = tDate.getDate().toString().padStart(2, '0');
    const tyear = tDate.getFullYear(); 
    const todDate = `${tmonth}/${tday}/${tyear}`;
    const todayDate = new Date(todDate).getTime();
   
    const givenSDate = new Date(startdate);
    const gsmonth = (givenSDate.getMonth() + 1).toString().padStart(2, '0');
    const gsday = givenSDate.getDate().toString().padStart(2, '0');
    const gsyear = givenSDate.getFullYear(); 
    const cmpSDate = `${gsmonth}/${gsday}/${gsyear}`;
    const CompareSDate = new Date(cmpSDate).getTime();

    const givenEDate = new Date(enddate);
    const gemonth = (givenEDate.getMonth() + 1).toString().padStart(2, '0');
    const geday = givenEDate.getDate().toString().padStart(2, '0');
    const geyear = givenEDate.getFullYear(); 
    const cmpEDate = `${gemonth}/${geday}/${geyear}`;
    const CompareEDate = new Date(cmpEDate).getTime();
 
    if(startdate && CompareSDate > todayDate) expired = true;
    
    if(enddate && CompareEDate < todayDate)  expired = true;
    
    if(startdate && enddate && CompareEDate < todayDate && CompareSDate > todayDate)  expired = true;
   
    return expired;
}


export const  recurringLiftValue = (Lift_Base, lift, lift_Max_Start, Lift_Minimum, Lift_Maximum, Round_Base) => {
          const campaignLiftValues = {};
    
        // Calculate Lift_Base_new
        let Lift_Base_new = Lift_Base * (1 + lift / 100);
        if (Lift_Base_new < Lift_Maximum) {
            Lift_Base = Lift_Base_new;
        }
    
        // Adjust Lift_Base within bounds
        if (Lift_Base < Lift_Minimum) {
            Lift_Base = Lift_Minimum;
        }
    
        const Lift = lift / 100;
    
        // Calculate Lift_Base_Digits_Count
        let Lift_Base_Digits_Count = Math.floor(Math.log10(Lift_Base)) + 1;
        if (Lift_Base_Digits_Count - Round_Base < 0) {
            Round_Base = 0;
        } else {
            Round_Base = Lift_Base_Digits_Count - Round_Base;
        }
    
        // Calculate Base and Remainder
        let Base = parseInt(Lift_Base / Math.pow(10, Round_Base));
        let Remainder = Lift_Base - (Base * Math.pow(10, Round_Base));
        let Remainder_Multiple_5 = parseInt(Remainder / (5 * Math.pow(10, (Round_Base - 1))) + 0.5);
        let Remainder_Rounded = Remainder_Multiple_5 * 5 * Math.pow(10, (Round_Base - 1));
        let Base_10 = Base * Math.pow(10, Round_Base);
        let Start = parseInt(Base_10 + Remainder_Rounded);
    
        // Adjust Start within bounds
        if (Start < Lift_Minimum) {
            Start = parseInt(Lift_Minimum);
        }
        if (Start > Lift_Maximum) {
            Start = parseInt(Lift_Maximum);
        }
        if (Start > lift_Max_Start) {
            Start = parseInt(lift_Max_Start);
        }
    
        // Calculate Lift_Calculated and Lift_Adjusted
        let Lift_Calculated = Lift_Base * Lift;
        let Lift_Adjusted = Lift_Calculated + Lift_Base - Start;
        let Lift_Digits_Count = parseInt(Math.floor(Math.log10(Lift_Calculated)));
       
        // Calculate Lift_Base_Round
        let Lift_Base_Round = Lift_Adjusted / Math.pow(10, Lift_Digits_Count);
        let Lift_Base_Round_Left = parseInt(Lift_Base_Round);
        let Lift_Base_Round_Right = removedecimal(Lift_Base_Round - Lift_Base_Round_Left);
     
        // Calculate nearest multiple of 5
        let Lift_Base_Round_Right_Multiple_5 = parseFloat(Lift_Base_Round_Right) / 0.5;
        let Lift_Base_Round_Right_Nearest_5=0;
        if(Lift_Base_Round_Right_Multiple_5 <= 0.5){
             Lift_Base_Round_Right_Nearest_5 = Math.floor(Lift_Base_Round_Right_Multiple_5) * 0.5;
        }else{
             Lift_Base_Round_Right_Nearest_5 = Math.round(Lift_Base_Round_Right_Multiple_5) * 0.5;
        }
   
        // Calculate Increment
        let Increment = (Lift_Base_Round_Left + Lift_Base_Round_Right_Nearest_5) * Math.pow(10, Lift_Digits_Count);
     
        Increment = parseInt(0.99 + (Increment / 10)) * 10;
       
        // Add values to campaignLiftValues object
        campaignLiftValues["StartValue"] = Start;
        campaignLiftValues["Increment"] = Increment;
    
        return campaignLiftValues;
    
    
};



export const getBoostUpDonationValues = (paymentOptions, cookieData) => {
   
    const { StartValue, Increment, defaultSelection, maxAmountVal } = cookieData;
 
    // Loop through each payment option
    paymentOptions.forEach((paymentOption) => {
      let currentValue = StartValue; // Initialize current value to StartValue
      if (paymentOption.Value.PaymentType === "Single Payment") {
  
        // Filter DonationItemList to exclude items where the DefaultValue exceeds maxAmountVal
        paymentOption.Value.DonationItemList = paymentOption.Value.DonationItemList.filter((item, index, self) => {
          
          if (item.Type !== "Open") {
            const previousItem = self[index - 1];
            item.DefaultValue = currentValue;
            item.DefaultValueDisplay = currentValue.toFixed(2);
            item.DefaultSelection = parseInt(defaultSelection) === index + 1 ? true : false;
            currentValue += Increment ? parseInt(Increment) : parseInt(maxAmountVal - currentValue); // Increment the value for the next item
            // If it's the last item in the list and the DefaultValue exceeds maxVal, set DefaultValue to maxVal
                if (item.DefaultValue > maxAmountVal) {
                    item.DefaultValue = maxAmountVal;
                    item.DefaultValueDisplay = maxAmountVal.toFixed(2);
                }
            // Keep the item only if DefaultValue is less than or equal to maxAmountVal
            return item.DefaultValue <= maxAmountVal && (!previousItem || previousItem.DefaultValue !== item.DefaultValue);
          }
          return true;
        });
      }
    });
  
    return paymentOptions;
  };

  export const getLiftUpDonations = (seedAmt,formSettings,amountsArray) => {

    let maxValue = formSettings.InteliGiveSettings.Maximum, minValue = formSettings.InteliGiveSettings.Minimum;
  
      const liftVals = recurringLiftValue(seedAmt, formSettings.InteliGiveSettings.LiftRate,maxValue,minValue, maxValue,1);
    
      const liftData = {
        ...liftVals,
        minAmountVal:formSettings.InteliGiveSettings.Minimum,
        maxAmountVal: formSettings.InteliGiveSettings.Maximum,
        defaultSelection: formSettings.InteliGiveSettings.DefaultSelection,
        trackingVal: formSettings.InteliGiveSettings.TrackingOption === 1 ? formSettings.OrganizationID : 0
      }
      sessionStorage.setItem('boostUp', JSON.stringify(liftData));
      sessionStorage.setItem('boostClicked', true);
      sessionStorage.setItem('seedAmount', seedAmt);
      return liftData;
  };
  

export const formatStreamName = (format, item, currency, hasIntelligive) => {  
    
    return format
      .replaceAll("{Patron_First_Name}", item.firstName)
      .replaceAll("{Patron_Last_Name}", item.lastName)
      .replaceAll("{Patron_First_Initial}", item.firstName.charAt(0))
      .replaceAll("{Patron_Last_Initial}", item.lastName.charAt(0))
      .replaceAll("{Amount}" , `${hasIntelligive ? formatStreamAmount(format, item, currency, hasIntelligive): ''}`)
      .replaceAll("{Comments}" , '')
     
     
  };


export  const formatStreamAmount = (format, item, currency, hasIntelligive) => {

    if (format.includes("{Amount}")) {
      return `${currency}${Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(removedecimal(item.amount))}${item.periodicity && hasIntelligive && item.periodicity !=='One Time Only'? '/'+item.periodicity:'' }`;
    }
  
  };

export  const formatStreamComments = (format, item) => {
    if (format.includes("{Comments}")) {
        return item.comments ? item.comments : '';
      }
 
  };
  
  export const formatSuccessMessage = (message, data, total, orderNumber, currency) => {  
    
    return message
      .replaceAll("{Patron_First_Name}", data.first_name)
      .replaceAll("{Patron_Last_Name}", data.last_name)
      .replaceAll("{Patron_First_Initial}", data.first_name.charAt(0))
      .replaceAll("{Patron_Last_Initial}", data.last_name.charAt(0))
      .replaceAll("{Amount}" , `${currency}${Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(removedecimal(total))}`)
      .replaceAll("{Order_Number}", orderNumber)
      .replaceAll("{Transaction_Result}", "Transaction processed successfully")
     
     
  };

  export const  cnpGetDisplayLabelForRecurring = (key) =>{
    if (key) {
      switch (key) {
        case "One Time Only":
          return "One Time";
        case "One Time":
          return "One Time";
        case "Week":
          return "Weekly";
        case "2 Weeks":
          return "2 Weeks";
        case "Month":
          return "Monthly";
        case "2 Months":
          return "2 Months";
        case "Quarter":
          return "Quarterly";
        case "6 Months":
          return "6 Months";
        case "Year":
          return "Yearly";
        default:
          return key;
      }
    } else {
      return "";
    }
  };

  export const filterButtonsToShowSix = (array) => {
   
    const activeButtons = array.filter((btn) => btn.Type !== "Open");
 
    const filteredButtons = activeButtons.length > 5
        ? activeButtons.slice(0, 5)
        : activeButtons;
        
    const openButton = array.find((btn) => btn.Type === "Open");

    if (openButton) {
        filteredButtons.push(openButton);
    }

    return filteredButtons;       
  

};


