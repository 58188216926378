
export  const SALT_KEY = "gatheringjar$@0047";
export const CSS_PATH = "https://aws.assets.clickandpledge.com/zoomapp";

//Live start here

export  const AUTH_API = "https://ryl3wy4jlj.execute-api.us-east-1.amazonaws.com/givent";
export  const ZOOM_USER_API = "https://vnuc3n3svc.execute-api.us-east-1.amazonaws.com/givent";
export  const ZOOM_SDB_API = "https://gqkkm60foh.execute-api.us-east-1.amazonaws.com/givent";
export  const GET_FORM_API = "https://k0n2u364yj.execute-api.us-east-1.amazonaws.com/givent";
export  const GET_FORM_BY_PARAMS_API = "https://teobg0rds1.execute-api.us-east-1.amazonaws.com/givent";
export  const SUBMIT_API = "https://utqnfro7z9.execute-api.us-east-1.amazonaws.com";
export  const FORM_SETTINGS_API = "https://vwl35bql8c.execute-api.us-east-1.amazonaws.com/givent";
export const SUBMIT_SOAP_API="https://u8u6qhnpwk.execute-api.us-east-1.amazonaws.com/givent";

//Live Ends




